html {
    margin: 0;
    width: 100%;
}

body {
    margin: 0;
    font-family: vag-rundschrift-d, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: rgb(174,196,212);
    background: radial-gradient(circle, rgba(174,196,212,1) 0%, rgba(144,168,186,1) 47%, rgba(82,110,130,1) 100%);
    width: 100%;
}

