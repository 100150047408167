.App {
    padding: 75px 50px;
}

.App-logo {
    width: 50%;
    pointer-events: none;
}

@media (max-width: 800px) {
    .App {
        padding: 75px 20px;
    }

    .App-logo {
        width: 75%;
    }
}

@media (max-width: 400px) {
    .App {
        padding: 75px 5px;
    }
}

.header {
    text-align: center;
}

.game-list-container {
    width: 100%;
    margin-top: 50px;
    border-collapse: collapse;
}

.game-list-header th {
    border-bottom: 4px solid #EAF4FB;
    padding-bottom: 15px;
}

.game-list-header th:nth-of-type(2) {
    width: 40%;
    text-align: center;
}

.game-list-header th:first-of-type {
    width: 30%;
    text-align: left;
    padding-left: 5px;
}

.game-list-header th:last-of-type {
    width: 30%;
    text-align: right;
    padding-right: 5px;
}

.loading {
    color: #FFFFFF;
}

.meeple,
.header-name {
    display: inline-block;
}

.header-name {
    color: #FFFFFF;
    font-size: 36px;
}

.amber-header {
    margin: 0 0 0 15px;
}

.ben-header {
    margin: 0 15px 0 0 ;
}

@media (max-width: 800px) {
    .meeple {
        height: 25px;
    }

    .header-name {
        font-size: 24px;
    }

    .phoebe {
        width: 55px;
    }
}

@media (max-width: 400px) {
    .meeple {
        height: 20px;
    }

    .header-name {
        font-size: 18px;
    }
}

