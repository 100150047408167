.game-col {
    border-bottom: 2px solid #EAF4FB;
    padding-top: 30px;
    padding-bottom: 30px;
}

.first-col {
    text-align: left;
    padding-left: 15px;
    position: relative;
}

.middle-col {
    text-align: center;
}

.last-col {
    text-align: right;
    padding-right: 15px;
    position: relative;
}

.win-count {
    color: #FFFFFF;
    font-size: 40px;
    margin: 0 0 5px 0;
}

.win-label {
    color: #EAF4FB;
    font-size: 32px;
    margin: 0;
}

.game-name {
    font-size: 40px;
    color: #FFFFFF;
    margin: 0;
}

@media (max-width: 400px) {
    .game-name {
        font-size: 30px;
    }
}

.play-count {
    font-size: 24px;
    color: #EAF4FB;
    margin: 0;
}

.first-col .trophy,
.last-col .trophy {
    position: absolute;
    height: 50px;
    margin-top: -20px;
    top: 50%; 
}

.first-col .trophy {
    right: 35%;
}

.last-col .trophy {
    left: 35%;
}

@media (max-width: 800px) {
    .first-col .trophy,
    .last-col .trophy {
        height: 30px;
    }

    .first-col .trophy {
        right: 10%;
    }

    .last-col .trophy {
        left: 10%;
    }
}

@media (max-width: 400px) {
    .first-col .trophy,
    .last-col .trophy {
        height: 25px;
    }
}

